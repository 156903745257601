import Cookies from 'js-cookie';

export function isLoggedIn() {
  return !!Cookies.get('User');
}

export function getUser() {
  const user = Cookies.get('User');
  return user ? JSON.parse(user) : {};
}
