import { navigate } from 'gatsby-link';
import { useEffect } from 'react';
import 'react-phone-number-input/style.css';
import '../assets/style/globalStyles.css';
import { isLoggedIn } from '../utils/validateUserToken';

export default function IndexPage({ location }) {
  // using this useEffect to automatically redirect to login page if it hits / route
  // TODO: Add logic for auth token to redirect them to login or dashboard page
  useEffect(() => {
    if (isLoggedIn) {
      if (location?.pathname === '/') {
        navigate('/app/dashboard');
      } else {
        navigate(location.pathname);
      }
    } else {
      navigate('/login');
    }
  }, []);

  return null;
}
